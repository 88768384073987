@import url('https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@100;200;300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@100;200;300;400;500;600;700;900&display=swap');

* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

:root {
    /* Background Color */
    --primary-color: rgb(239 68 68);
    --secondary-color: #fc5185;
    --bg-light-blue: #3672bc;
    --bg-dark: black;
    --bg-white: #fff;

    /* Text Style */
    --primary-font: 'Roboto Slab', serif;
    --secondary-font: 'Josefin Sans', sans-serif;
    --primary-text: #004caa;
    --secondary-text: #fc5185;
    --text-white: #fff;
    --text-black: #222;
    --text-gray: #666;
    --text-light: #ddd;
}

body {
    font-family: var(--primary-font);
    background-color: var(--bg-white);
}

a {
    text-decoration: none;
}

::-webkit-scrollbar {
    width: .375rem;
}

::-webkit-scrollbar-track {
    background: var(--bg-light-blue);
}

::-webkit-scrollbar-thumb {
    background: var(--secondary-color);
}

.wrapper{
    padding:6.25rem 0;
}
.main-btn{
    display: inline-block;
    border:.0625rem solid var(--secondary-color);
    color:var(--text-dark);
    text-align: center;
    padding:.75rem 1.375rem;
    font-size: 1rem;
    background:transparent;
    text-transform: capitalize;
    border-radius: .1875rem;
    transition:all 0.3s ease-in-out;
}
.main-btn:hover{
    background-color:var(--secondary-color);
    border-color:var(--secondary-color);
    color:var(--text-white);
}
.fill-btn{
    background-color:var(--secondary-color);
    border-color:var(--secondary-color);
    color:var(--text-white);
}
.fill-btn:hover{
    background-color:var(--bg-white);
    border-color:var(--bg-white);
    color:var(--text-black)
}
h1{
    font-size: 3.875rem;
    margin-bottom:1.25rem;
    font-weight: 600;
    color:var(--text-black);
    font-family: var(--secondary-font);
}
h2{
    font-size: 2.875rem; 
    font-weight: 600;
    color:var(--text-dark);
    font-family: var(--secondary-font);
}
h3{
    font-size: 2.25rem; 
    font-weight: 600;
    color:var(--text-white);
    font-family: var(--secondary-font);
}
h4{
    font-size: 1.5rem; 
    font-weight: 600;
    line-height: 2.25rem;
    color:var(--text-black);
    font-family: var(--secondary-font);
}
h5{
    font-size: 1.5rem; 
    line-height: 1.2;
    font-weight: 500;
    color:var(--primary-text);
    margin-bottom:.9375rem;
    font-family: var(--secondary-font);
}
h6{
    font-size: .875rem; 
    font-weight: 300;
    color:var(--text-black);
    margin-bottom:.9375rem;
    text-transform: uppercase;
    font-family: var(--secondary-font);
}
p{
    font-size: 1rem;
    color:var(--text-gray);
    line-height: 1.625rem;
} 
.card{
    border-radius: .625rem;
    background-color:var(--bg-white);
    box-shadow: .5084rem 1.1419rem 2.5rem 0 rgb(0 0 0 / 8%);
    padding:3.75rem 3.125rem 4.6875rem;
    -webkit-transition:all .4s;
    transition:all .4s;
    border:0;
}
@keyframes movebounce{
    0%{
        -webkit-transform:translateY(0);
        transform:translateY(0);
    }
    50%{
        -webkit-transform:translateY(1.25rem);
        transform:translateY(1.25rem);
    }
    100%{
        -webkit-transform:translateY(0);
        transform:translateY(0);
    }
}
@media (max-width: 1199.5px) {
        /* header */ 
        .header_wrapper .navbar-nav .nav-link{
            font-size: 1rem;
        }    
}

@media (max-width:991px) {
    .main-btn{
        font-size: .875rem;
    }
    h1{
        font-size: 2.8125rem;
        margin-bottom:.625rem;
    }
    h2{
        font-size: 1.875rem; 
    }
    h3{
        font-size: 1.5625rem; 
    }
    h4{
        font-size: 1.25rem; 
    }
    h5{
        font-size: 1.125rem; 
    }
    p{
        font-size: .875rem;
        line-height: 1.35rem;
    }
}
@media (max-width: 767px) {
      /* custom css */ 
      .main-btn{
        font-size: .875rem;
    }
    h1{
        font-size: 2.5rem;
        margin-bottom:2.5rem;
    } 
    h5{
        font-size: 1.25rem; 
    } 

    /* service */ 
    .services_wrapper .card{
        text-align: center;
    }
}