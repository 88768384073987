.maindiv{
    overflow-x: hidden;
  }
.backcolor{
    /* background-color: rgb(217, 222, 222); */
    height: 250px;
   
}
.images{
    height: 100px;
    width: 100px;
}
.cards{
    height: 200px;
    box-shadow: 0 4px 8px rgba(98, 132, 152, 0.893);
   
}
.text{
    color: rgb(40, 57, 114);
}