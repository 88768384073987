.banner_wrapper{
    display: flex;
    width: 100%;
    padding: 100px 0 125px;
    background: url(../img/bg.png) no-repeat;
    background-size: cover;
    justify-content: center;
    align-items: center;
    /* height: 100vh; */
    /* overflow: hidden; */
  }
  .banner_wrapper .banner-subtitle {
    font-size: 24px;
    font-weight: 700;
    line-height: 1.5;
    margin-bottom: 15px;
    color: var(--primary-text);
  }
  .banner_wrapper .banner-title {
    font-weight: 800;
    font-size: 40px;
    text-transform: capitalize;
    line-height: 1.15;
    text-align: start;
    margin-bottom: 25px;
    color: black;
  }
  .banner_wrapper .banner-title span {
    color: var(--secondary-color);
  }
  .banner_wrapper .banner-title-text {
    font-size: 17px;
    font-weight: 600;
    line-height: 1.5;
    margin-bottom: 40px;
    color: black;
  }
   
  .banner_wrapper .header-img-section img {
    width: 100%;
    animation: float 5s ease-in-out infinite;
  }
  @keyframes float {
    0% {
        transform: translatey(0px);
    }
    50% {
        transform: translatey(20px);
    }
    100% {
        transform: translatey(0px);
    }
  }